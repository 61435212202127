<template>
  <div class="p-1">
    <small v-for="log in auditLogs" :key="log.id">
      <div class="">last edited by {{ log.displayname }}:</div>
      <div class="">action: {{ log.log.action }}</div>
      <div class="mb-1">
        {{ getDateTime(log.created_at) }}
      </div>
    </small>
  </div>
</template>

<script>
import auditLogApi from '@api/audit_logs';
import { queryParameters } from '@/schema';

export default {
  name: 'StockInOutLogsSection',
  props: {
    header_id: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({
    auditLogs: [],
  }),
  mounted() {
    this.loadLogs();
  },
  methods: {
    loadLogs() {
      const params = {
        sort: queryParameters.sort,
        page: queryParameters.page,
        status: queryParameters.status,
      };

      params.filterExt = `header_id=${this.$route.query.id},model='StockInOut'`;

      auditLogApi
        .list(params)
        .then(({ data }) => {
          this.auditLogs = data;
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    getDateTime(dt) {
      return;
    },
  },
};
</script>

<style></style>
