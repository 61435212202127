import { render, staticRenderFns } from "./SectionComments.vue?vue&type=template&id=319e6b0d"
import script from "./SectionComments.vue?vue&type=script&lang=js"
export * from "./SectionComments.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports